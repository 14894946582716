import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Head from 'components/head';
import { Heading, Text, Flex, Box } from '@chakra-ui/react';
import { accentText } from '../../constants/theme';
import Proyectos from '../../components/proyectos';
const Device = React.lazy(() => import('../../components/device'));
import screenShot from '../../../content/images/servicios/ssJuegosVisualmacc.jpg';
import ContactBanner from '../../components/contactBanner';
import { StaticImage } from 'gatsby-plugin-image';
import ogImg from '../../../content/images/servicios/Apps_y_Videojuegos-interactivos-Mexico3.jpg';

const Apps = ({ lang='es' }) => {
  const l = (...texts) => lang === 'en' ? texts[1] || texts[0] : texts[0]

  const isSSR = typeof window === 'undefined';  
  return (
    <Layout lang={lang}>
      <Head lang={lang}
        pageTitle={l('🎮  Aplicaciones interactivas y Videojuegos', '🎮  VideoGames and Applications')}
        imageUrl={'https://visualma.com' + ogImg}
        pageDescription={
          l('Creamos Apps y videojuegos para todo tipo de dispositivos.  Desarrollo de Experiencias web, Apps, show rooms, instalaciones interactivas y juegos en México',
          'We create Apps and Videogames for all kind of devices, web experiencies, virtual show rooms and interactive installations')
        }
      />
      <div>

        {/* ----- HeroImage ----- */}
        <Flex justifyContent="flex-end">
          <h2 className="pageHeader">
            {l('Aplicaciones y Videojuegos', 'App\'s and VideoGames')}
          </h2>
        </Flex>

        <StaticImage
          className="headerImg"
          layout="fullWidth"
          formats={['auto', 'webp', 'avif']}
          loading="eager"
          src="../../../content/images/servicios/Apps_y_Videojuegos-interactivos-Mexico3.jpg"
          alt={l('Aplicaciones y Videojuegos', 'App\'s and VideoGames')} 
          />
        
        <Flex className="headerTitleWrapper">
          <div>
            <h1 className="parrafo parrafoGrande headerTitle">
              {l(<>Desarrollamos <b>Aplicaciones y Videojuegos</b> para todo tipo de dispositivos</>,
                <>We develop <b>App&apos;s and Videogames</b> for every device</>)}
            </h1>
          </div>
        </Flex>
        {/* ----------------------- */}

        <Flex
          bg="transparent"
          justify="flex-start"
          direction="column"
          m="2em auto 5em"
          maxWidth="40em"
        >
          <Heading as="h2" className="subHeader" mb="0.5em">
            <small>
              {l('Experiencias memorables en web, aplicaciones, show rooms, instalaciones interactivas y juegos',
                'Memorable web experiences, app\'s, show rooms, interactive installations and videogames')}
              {' '}
              <span role="img" aria-label="emoji de cabeza explotando">
                🤯
              </span>
            </small>
          </Heading>
        </Flex>

        {!isSSR && (
          <React.Suspense fallback={
            (
              <Box height={['300px', '400px', '500px']} textAlign="center"
              >
                Cargando <span role="img" aria-label="icon">⏳</span>
              </Box>
            )}>
            <Device type="laptop" screenshot={screenShot} screenshotHeight={10645} />
          </React.Suspense>
        )}

        <Heading as="h3" className="" textAlign="center" mt="1.9em" mb="1.4em" fontSize="2xl">
          {l(<>Soluciones que dan una <b style={{ color: accentText }}>presencia digital</b> a marcas, productos y eventos</>,
            <>Solutions that give <b style={{ color: accentText }}>digital presence</b> to brands, products and events</>)}

        </Heading>
        <Text className="parrafoChico">
          {l('Llevamos tu idea a todos los dispositivos móviles y a cualquier sistema operativo, Web, IOS, Android, Windows, Mac OSX y Linux.', 'We take your idea to all mobile devices and operating systems, Web, IOS, Android, Windows, Mac OSX & Linux.')}
        </Text>

        <Flex className="flexList">
          <Flex className="columna">
            <Heading as="h3" className="subHeader">
              App&apos;s
            </Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Aplicaciones móviles disponibles para todos los dispositivos.', 'Mobile Applications for all kind of devices.')}
            </Text>
          </Flex>
          <Flex className="columna">
            <Heading as="h3" className="subHeader">
              Web
            </Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Sistemas web, portales, micro sitios y experiencias al alcance de un click.',
                'Web sites, micro sites & web experiences that are one click away')}
            </Text>
          </Flex>

          <Flex className="columna">
            <Heading as="h3" className="subHeader">
              {l('Redes sociales','Social networks')}
            </Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Integramos tu campaña a todas las redes sociales.',
              'We integrate your campaign to all the social networks.')}
            </Text>
          </Flex>

          <Flex className="columna">
            <Heading as="h3" className="subHeader">
              {l('Realidad Virtual','Virtual Reality')}
            </Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Experiencias inmersivas que permiten al usuario transportarse a otros mundos.',
                'Inmersive experiences that allow the user to live other worlds.')}
            </Text>
          </Flex>
          <Flex className="columna">
            <Heading as="h3" className="subHeader">
              {l('Realidad Aumentada','Augmented Reality')}
            </Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Integramos el universo digital y el mundo real en experiencias futuristicas.',
                'We integrate the digital universe and the real world in futuristic experiences')}
            </Text>
          </Flex>

          <Flex className="columna">
            <Heading as="h3" className="subHeader">
              {l('Aplicaciones Educativas','Educational tools')}
            </Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Herramientas didácticas que ayudan a aprender y visualizar conceptos.',
                'Didactic tools that help to learn and visualize concepts.')}
            </Text>
          </Flex>

          <Flex className="columna">
            <Heading as="h3" className="subHeader">
              Show Room&apos;s
            </Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Construimos un mundo ideal para tus clientes.',
                'We build an ideal world for your clients.')}
            </Text>
          </Flex>
          <Flex className="columna">
            <Heading as="h3" className="subHeader">
              {l('Instalaciones Interactivas','Interactive installation\'s')}
            </Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Instalaciones memorables y divertidas.',
                'Fun and memorable installation\'s')}
            </Text>
          </Flex>

          <Flex className="columna">
            <Heading as="h3" className="subHeader">
              {l('Video Juegos','VideoGames')}
            </Heading>
            <Text fontSize="xl" className="parrafoChico">
              {l('Diversión para tus usuarios en web, redes y movil.',
                'Fun for your users in the web, social networks, vr and mobile.')}
            </Text>
          </Flex>
        </Flex>

        <ContactBanner lang={lang}/>

        <Proyectos
          lang={lang}
          title={l('Proyectos de Aplicaciones y Videojuegos','App\'s and Videogame Projects')}
          category="Aplicaciones y Videojuegos"
          limit={3}
        />
      </div>
    </Layout>)
};
Apps.propTypes = {
  lang: PropTypes.string
};

export default Apps;
